var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"spinning":_vm.ifSub}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"当前处理人","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['currentUserId',
              {
                validateTrigger: ['change'],
                rules: [{
                  type: 'string',
                  required: true,
                  message: '请选择当前处理人',
                }]
              }
            ]),expression:"['currentUserId',\n              {\n                validateTrigger: ['change'],\n                rules: [{\n                  type: 'string',\n                  required: true,\n                  message: '请选择当前处理人',\n                }]\n              }\n            ]"}],staticStyle:{"width":"100%"},attrs:{"treeDefaultExpandAll":"","disabled":"","treeNodeFilterProp":"title","dropdownStyle":{ maxHeight: '400px', overflow: 'auto' },"treeData":_vm.orgUserTreeData,"placeholder":"请选择当前处理人"}})],1),_c('a-form-item',{attrs:{"label":"处理结果","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['result']),expression:"['result']"}],attrs:{"type":"textarea","rows":"4","placeholder":"请输入处理结果"}})],1),_c('a-form-item',{attrs:{"label":"是否完结：","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['finished',{initialValue: 'false',rules:[{type:'string',required:true,message:'是否完结为必填项'}],trigger:'change'}]),expression:"['finished',{initialValue: 'false',rules:[{type:'string',required:true,message:'是否完结为必填项'}],trigger:'change'}]"}],attrs:{"options":_vm.finishedOptions},on:{"change":_vm.radioChanged}})],1),(this.formItem.finished=='false')?_c('a-form-item',{attrs:{"label":"下次处理人","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['nextProcessUserId',
              {
                validateTrigger: ['change'],
                rules: [{
                  type: 'string',
                  message: '请选择下次处理人',
                }]
              }
            ]),expression:"['nextProcessUserId',\n              {\n                validateTrigger: ['change'],\n                rules: [{\n                  type: 'string',\n                  message: '请选择下次处理人',\n                }]\n              }\n            ]"}],staticStyle:{"width":"100%"},attrs:{"treeDefaultExpandAll":"","treeNodeFilterProp":"title","dropdownStyle":{ maxHeight: '400px', overflow: 'auto' },"treeData":_vm.orgUserTreeData,"placeholder":"请选择下次处理人"}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"上传图片","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('qiniu-image-upload',{ref:"qiniuImageRef",attrs:{"document-dir-id":41,"multiSelection":true,"type":"edit","items":_vm.formItem.images},on:{"getImageData":_vm.getImageData}})],1),_c('a-form-item',{attrs:{"label":"上传文件","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('qiniu-file-upload',{ref:"qiniuFileRef",attrs:{"document-dir-id":42,"multiSelection":true,"type":"edit","items":_vm.formItem.files},on:{"getFileData":_vm.getFileData}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }